<app-top-bar *ngIf="showTopBar"></app-top-bar>
<router-outlet></router-outlet>
<app-cart-modal [visible]="modalVisible" [modalId]="'cart-modal'"></app-cart-modal>
<app-modal id="archived-advert-notice">
    <aside class="modal">
      <div class="modal-body">
        <div class="header">
          <div class="title-text" >Try this similar promotion</div>
          <div class="close" (click)="closeArchivedAdvertNotice()"><span class="icon">&#x00D7;</span></div>
        </div>
        <div>
          <p>
            The promotion you selected is no longer available, but we have directed you to the next best promotion available. 
          </p>
        </div>
        <div class="flex flex-column">
          <button class="button regular solid-green full-width" (click)="closeArchivedAdvertNotice()">Ok</button>
        </div>
      </div>
    </aside>
    <div class="modal-background"></div>
</app-modal>
