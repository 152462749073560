import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../core/services/data/user.service';

@Component({
	selector: 'app-landing',
	templateUrl: './landing.component.html',
	styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
	constructor(
		private us: UserService,
		private router: Router,
	) { }

	ngOnInit() {
		this.us.fetchUser().then((user: any) => {
			this.router.navigate([`/author-dashboard/${user.authorLink}/features${window.location.search}`]);
		}, () => {
			this.router.navigate([`/features${window.location.search}`]);
		});
	}
}
