import { Injectable } from '@angular/core';
import { ServerConnectionService } from './server-connection.service';

import { CoreModule } from '../core.module';
import { EnvironmentService } from './environment.service';

@Injectable({
	providedIn: CoreModule,
})
export class LogsService {
	constructor(
		private environmentService: EnvironmentService,
		private scs: ServerConnectionService,
	) { }

	/**
   * Accepts a log object containing a type and message field
   * @param log: { type: '', message: '' }
   */
	public postLog(log) {
		this.scs.http$('POST', '/server/log', null, log).subscribe();
	}
}
